.container,
.logout-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 50px;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
}

.sign-in-btn {
  font-size: 20px;
  height: 50px;
}

.sign-in-btn.ant-btn {
  background: #0067b8;
  color: #fff;
}

.sign-in-btn.ant-btn:hover {
  background: #fff;
  color: #0067b8;
  border-color: #0067b8;
}

.logout-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: #dcdcdc;
}