.main-container {
  width: 100%;
  height: calc(100vh - 118px);
  margin-top: 64px;
  padding: 0 20px;
}

.content-container {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-around;
  background-color: #fff;
  padding: 10px 50px 40px;
  flex-direction: column;
  margin-top: 20px;
}

.main-container .ant-descriptions-item-label,
.main-container .ant-descriptions-item-content {
  font-size: 17px;
}

.main-container .ant-descriptions-item-content {
  font-weight: 600;
}

.page-header-description {
  width: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.07);
  padding: 0 50px;
}

.tabs {
  width: 100%;
  background-color: #f0f2f5;
  padding: 20px;
  height: 100%;
}

.ant-tabs,
.ant-tabs-content-holder {
  height: calc(100% - 85px);
}

.tabs-container {
  width: 100%;
  height: calc(100vh - 277px);
  margin: 0 auto 30px;
  display: flex;
  justify-content: space-between;
}

.tabs p {
  margin: 0;
}

.tabs > .ant-tabs-card .ant-tabs-content {
  height: calc(100% - 10px);
  margin-top: -16px;
}

.tabs > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: #fff;
}

.tabs > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}

.tabs > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}

#components-tabs-demo-card-top .code-box-demo {
  padding: 24px;
  overflow: hidden;
  background: #f5f5f5;
}

[data-theme='compact'] .tabs > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -8px;
}

[data-theme='dark'] .tabs > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}

[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
  background: #000;
}

[data-theme='dark']
  .tabs
  > .ant-tabs-card
  .ant-tabs-content
  > .ant-tabs-tabpane {
  background: #141414;
}

[data-theme='dark'] .tabs > .ant-tabs-card .ant-tabs-tab-active {
  background: #141414;
  border-color: #141414;
}

.star {
  margin-top: -3px;
}

.tabs .ant-form-item {
  margin-bottom: 24px;
}

.submit-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs .ant-form-item,
.tabs .ant-form-item label {
  font-size: 16px;
}
