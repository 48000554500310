.main-container-list {
  width: 100%;
  height: calc(100vh - 100px);
  margin-top: 69px;
  padding: 0 20px;
}

.content-container-waiting-list {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  background-color: #fff;
  padding: 10px 50px 30px;
  flex-direction: column;
}

.card-wrapper {
  display: flex;
  height: calc(100% - 80px);
  padding-bottom: 30px;
}

.first-card {
  width: 100%;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}

.first-card .ant-btn-lg {
  height: auto;
  border-radius: 5px;
}

.second-card {
  width: 100%;
  height: 100%;
  padding: 0 30px 30px;
}

.next-call {
  margin: 50px 0;
}

.next-call label,
.next-call .ant-form-item-control-input-content {
  font-size: 22px;
  color: #0f0f0f;
}

.next-call .ant-form-item-control-input-content {
  font-weight: 900;
}

.ant-page-header-heading-title {
  font-family: Marcellus;
  font-size: 36px;
  height: 40px;
}
